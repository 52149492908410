<template>
<section class="wrapper bg-light">
	<div class="container py-7 py-md-8">
		<div class="row text-center">
			<div class="">
				<h2 class="fs-15 text-uppercase text-muted mb-3">Optimisation des investissements </h2>
				<h3 class="display-4 mb-10 px-xl-11">Une maîtrise complète de la chaîne de valeur</h3>
			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
		<div class="row gx-lg-8 gx-xl-12 gy-8">
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/note.svg"
								 class="svg-inject icon-svg icon-svg-sm text-green me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green">Analyse juridique</h4>
						<p class="mb-0">Chaque achat fait l'objet d'une analyse juridique de tous les aspects de l'investissement.</p>
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/location.svg"
								 class="svg-inject icon-svg icon-svg-sm text-red me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green">Emplacement prémium</h4>
						<p class="mb-0">Nous achetons uniquement des biens dont le potentiel de plus-values est important.</p>
					</div>
				</div>
			</div>

			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/resize-down.svg"
								 class="svg-inject icon-svg icon-svg-sm text-red me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green">Optimisation fiscale</h4>
						<p class="mb-0">Nous investissons dans biens nécessitant de nombreux travaux pour créer du déficit foncier et réduire la fiscalité.</p>
					</div>
				</div>
			</div>

			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/home.svg"
								 class="svg-inject icon-svg icon-svg-sm text-aqua me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green">Optimisation locative</h4>
						<p class="mb-0">Nous privilégions des baux de courte ou moyenne durée, la location meublée ou la colocation.</p>
					</div>
				</div>
			</div>
			<!--/column -->

			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/shield.svg"
								 class="svg-inject icon-svg icon-svg-sm text-purple me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green">Optimisation de la sécurité</h4>
						<p class="mb-0">Nous utilisons les technologies de la blockchain pour garantir l'intégrité des transactions.</p>
					</div>
				</div>
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-4">
				<div class="d-flex flex-row">
					<div>
						<img src="@/assets/media/svg/chart.svg"
								 class="svg-inject icon-svg icon-svg-sm text-purple me-4"
								 alt="" />
					</div>
					<div>
						<h4 class="mb-1 text-green">Optimisation de la valorisation</h4>
						<p class="mb-0">Nous maximisons le cours des tokens par un réinvestissement continu de l'ensemble des revenus locatifs.</p>
					</div>
				</div>
			</div>
			<!--/column -->

			<!--/column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'feature3',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
