<template>
<footer class="bg-dark text-inverse">
	<div class="container py-13 py-md-15 ">





		<div class="row gy-6 gy-lg-0">
			<div class="col-md-4 col-lg-3">
				<div class="widget">
					<a :href="link"
						 class="h1 text-white">
						<b v-html="title"></b>
					</a>
					<a :href="link"
						 class="h3 text-white"
						 v-html="title2">
					</a>
					<p class="mb-4">© {{copyright}} <br class="d-none d-lg-block" />All rights reserved.</p>
					<a :href="'mailto:'+mail"> {{mail}}</a><br />
					<a href="tel:+33766461728"> +33 766 461 728</a><br />
					<nav class="nav social social-white"
							 style="display:none">
						<a href="#"><i class="uil uil-twitter"></i></a>
						<a href="#"><i class="uil uil-facebook-f"></i></a>
						<a href="#"><i class="uil uil-dribbble"></i></a>
						<a href="#"><i class="uil uil-instagram"></i></a>
						<a href="#"><i class="uil uil-youtube"></i></a>
					</nav>
					<!-- /.social -->
				</div>
				<!-- /.widget -->
			</div>
			<!-- /column -->
			<div class="col-md-4 col-lg-3">
				<div class="widget">
					<h4 class="widget-title text-white mb-3">Contact </h4>
					<address class="pe-xl-15 pe-xxl-17">{{address1}}<br class="d-none d-md-block" /> {{address2}}</address>
					<a href="/cgu">Mentions légales et CGU </a>
				</div>
				<!-- /.widget -->
			</div>
			<!-- /column -->
			<div class="col-md-4 col-lg-3">
				<div class="widget">
					<h4 class="widget-title text-white mb-3">Découvir </h4>
					<ul class="list-unstyled  mb-0">

						<li v-for="(item, index) in pages"
								:key="'dm'+index"
								class="nav-item">
							<a :href="item.link">{{item.page}}</a>
						</li>
					</ul>
				</div>
				<!-- /.widget -->
			</div>
			<!-- /column -->
			<div class="col-md-12 col-lg-3">
				<div class="widget">
					<h4 class="widget-title text-white mb-3">Notre newletter</h4>
					<p class="mb-5">Souscrivez à notre newletter.</p>
					<div class="newsletter-wrapper">

						<div id="mc_embed_signup2">

							<div class="mc-field-group input-group form-floating">
								<input v-model="email"
											 type="email"
											 class="required email form-control"
											 placeholder="Email Address"
											 id="mce-EMAIL2">

								<label for="mce-EMAIL2">Adresse email</label>
								<input @click="form()"
											 type="submit"
											 value="Envoyer"
											 name="subscribe"
											 id="mc-embedded-subscribe2"
											 class="btn btn-primary">
							</div>

						</div>
						<!--End mc_embed_signup-->
					</div>
					<!-- /.newsletter-wrapper -->
				</div>
				<!-- /.widget -->
			</div>

			<span class="mt-10"
						style="font-size:80%"
						v-html="text" />


		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</footer>
</template>


<script>
export default {
	name: 'footer2',
	components: {},
	props: {
		title: String,
		address1: String,
		address2: String,
		title2: String,
		mail: String,
		copyright: String,
		text: String,
		origine: String,
		link: {
			type: String,
			default: "/"
		},
		pages: [],
		langs: [],
	},
	data: () => ({
		email: undefined,

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {



		form() {
			let opt = [];
			opt["api"] = "email"
			opt["key"] = this.key
			opt["debug"] = true
			opt["email"] = this.email
			opt["origine"] = this.origine
			this.$store.dispatch('post', opt).then(response => {
				if (response != undefined) this.email = "c'est fait";
			})
		},

	},
	watch: {}
}
</script>

<style scoped>
</style>
