<template>
<section class="wrapper bg-light">
	<div class="container pt-9 pt-lg-11 pb-14 pb-lg-6">
		<div class="row gx-lg-8 gx-xl-10 align-items-center">
			<div class="col-lg-6">
				<figure><img :src="image"
							 alt="" /></figure>
			</div>
			<!-- /column -->
			<div class="col-lg-6">
				<h2 class="fs-15 text-uppercase text-muted mb-3"
						v-html="accroche" />
				<h3 class="display-4 mb-5"
						v-html="titre" />
				<p v-if="lead !=''"
					 class="mb-8 lead"
					 v-html="lead" />
				<p v-if="text !=''"
					 class="mb-8 "
					 v-html="text" />

				<div class="row">
					<div class="col-12">
						<ul class="icon-list bullet-bg bullet-soft-green mb-0 row">
							<li v-for="(item, index) in elements"
									:key="index"><i class="uil uil-check"></i> {{item.text}}</li>
						</ul>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
				<div class="d-flex justify-content-center justify-content-lg-start mt-5">
					<span><a v-if="link_label1=''"
							 :href="link1"
							 class="btn btn-primary rounded me-2">{{link_label1}}</a></span>
					<span><a v-if="link_label2!=''"
							 :href="link2"
							 class="btn btn-yellow rounded">{{link_label2}}</a></span>
				</div>


			</div>
			<!-- /column -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'process1',
	components: {},
	props: {
		link_label1: String,
		link_label2: String,
		link1: String,
		link2: String,
		elements: [],
		accroche: String,
		titre: String,
		lead: String,
		text: String,
		image: String,
	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
