<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center">
			<div class="col-lg-6">
				<div class="row gx-md-5 gy-5">
					<div class="col-12">
						<figure class="rounded mx-5"><img src=" "
									 alt=""></figure>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<figure class="rounded"><img src=" "
									 alt=""></figure>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<figure class="rounded"><img src=" "
									 alt=""></figure>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
			<div class="col-lg-6">
				<h2 class="fs-16 text-uppercase text-muted mb-3">Ce qui fait la différence</h2>
				<h3 class="display-3 mb-10">L'immobilier à la porté de tous</h3>
				<div class="row gy-8">
					<div class="col-md-6">
						<div class="d-flex flex-row">
							<div>
								<img src=" "
										 class="svg-inject icon-svg icon-svg-xs solid-mono text-fuchsia me-4"
										 alt="" />
							</div>
							<div>
								<h4 class="mb-1">Rentabilité</h4>
								<p class="mb-0">de 9% à 14% de croissance des tokens</p>
							</div>
						</div>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<div class="d-flex flex-row">
							<div>
								<img src=" "
										 class="svg-inject icon-svg icon-svg-xs solid-mono text-violet me-4"
										 alt="" />
							</div>
							<div>
								<h4 class="mb-1">Diversification</h4>
								<p class="mb-0">Investir en France et à l'étranger </p>
							</div>
						</div>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<div class="d-flex flex-row">
							<div>
								<img src=" "
										 class="svg-inject icon-svg icon-svg-xs solid-mono text-orange me-4"
										 alt="" />
							</div>
							<div>
								<h4 class="mb-1">Liquidité</h4>
								<p class="mb-0">Place de marché & AMM.</p>
							</div>
						</div>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<div class="d-flex flex-row">
							<div>
								<img src=" "
										 class="svg-inject icon-svg icon-svg-xs solid-mono text-green me-4"
										 alt="" />
							</div>
							<div>
								<h4 class="mb-1">Fiscalité</h4>
								<p class="mb-0">Bénéficiez d'une fiscalité plafonnée</p>
							</div>
						</div>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'about2',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
