<template>
<section class="wrapper bg-light">
	<div class="container py-5 py-md-6">
		<h2 class="display-4 mb-3">{{titre}}</h2>
		<p class="lead fs-lg">{{lead}}</p>
		<p class="mb-8"
			 v-html="text" />
		<div class="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line">
			<div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">01</span></span>
				<h4 class="mb-1"
						v-html="it1" />
				<p class="mb-0"
					 v-html="id1" />
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">02</span></span>
				<h4 class="mb-1"
						v-html="it2" />
				<p class="mb-0"
					 v-html="id2" />
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">03</span></span>
				<h4 class="mb-1"
						v-html="it3" />
				<p class="mb-0"
					 v-html="id3" />
			</div>
			<!--/column -->
			<div class="col-md-6 col-lg-3"> <span class="icon btn btn-circle btn-lg btn-soft-primary disabled mb-4"><span class="number">04</span></span>
				<h4 class="mb-1"
						v-html="it4" />
				<p class="mb-0"
					 v-html="id4" />
			</div>

		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'process5',
	components: {},
	props: {
		titre: String,
		lead: String,
		text: String,
		it1: String,
		id1: String,
		it3: String,
		id3: String,
		it2: String,
		id2: String,
		it4: String,
		id4: String,

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
