<template>
<div id="app">
	<nav1 title="Fraction<span style='font-size:50%'>.immo</span>"
				title2=""
				:pages="pages"
				:langs="langs" />

	<router-view />

	<footer2 origine="fraction.immo"
					 text="
Fraction.re est une Société par actions simplifiée à capital variable de 250 000 euros minimum enregistrée au Registre du Commerce et des Sociétés de Paris sous le numéro 908 680 002
<br/>
<br/>Fraction.re (re pour Real Estate) a été fondé dans le but d'utiliser la technologie de la blockchain afin de révolutionner les technologies financières dans l'immobilier pour qu'elles soient plus efficaces, plus accessibles et plus transparentes.
 <br/><br/>Fraction.re n'est pas une agence immobilière, un marchand de biens, une société de courtage, un portail de financement, un souscripteur, une banque d'investissement, un conseiller en investissement ou un gestionnaire d'investissement, et ne fournit pas de services de courtage, de banque d'investissement ou de souscription, de recommandations ou de conseils d'investissement à quiconque.
<br/><br/><b>Fraction.re ne prend aucune part à la négociation ou à l'exécution de transactions pour l'achat ou la vente de titres ou de crypto-monnaie et n'est à aucun moment en possession de fonds ou de titres d'investisseurs dans le cadre de telles transactions.
</b>
"
					 title="Fraction"
					 title2=""
					 :pages="pages"
					 :langs="langs"
					 address1="Fraction.re "
					 address2="56 rue Monge 75005 Paris France"
					 copyright="2021-2022 Fraction.re SAS "
					 mail="info@fraction.re" />
</div>
</template>



<script>
import footer2 from "@/components/footer2.vue";
import nav1 from "@/components/nav1.vue";



export default {
	name: 'app',
	components: {
		nav1,
		footer2
	},
	props: {},
	data: () => ({
		pages: [{
				page: "Blog",
				link: "post/last"
			},
			{
				page: "Questions",
				link: "questions"
			},
			{
				page: "Smart-Contract",
				link: "smart"
			}
		],
		langs: [{
				lang: "FR",
				link: "fr"
			},
			{
				lang: "ES",
				link: "es"
			},
			{
				lang: "EN",
				link: "en"
			},
			{
				lang: "DE",
				link: "de"
			}
		]

	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>


<style>
@import 'assets/css/plugins.css';
@import 'assets/css/style.css';
@import 'assets/css/colors/orange.css';
@import 'assets/css/fonts/thicccboi.css';



html,
body {
	font-family: 'Montserrat', sans-serif;

}

#app {
	font-family: Montserrat, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
