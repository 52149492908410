<template>
<section class="wrapper bg-light">
	<div class="container py-7 py-md-8">
		<div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
			<div class="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative">
				<div class="shape bg-soft-primary rounded-circle rellax w-20 h-20"
						 data-rellax-speed="1"
						 style="top: -2rem; right: -1.9rem;"></div>
				<figure class="rounded"><img src="@/assets/media/business-model.jpg"
							 alt=""></figure>
			</div>
			<!--/column -->
			<div class="col-lg-6">
				<h2 class="display-4 mb-3">Des intérêts communs</h2>
				<p class="lead fs-lg">Un modèle économique qui repose exclusivement sur la valorisation des tokens.</p>
				<p class="mb-6">Rejoindre l'écosystème fraction.re c'est intégrer une communauté d'investisseurs
					en étant réellement propriétaire d'une fraction d'un ou plusieurs biens immobiliers.
					<br />Chaque acteur de l'écosystème est un investisseur, c'est ce qui fait la force de ce modèle économique.
					La seule et unique source de revenu de tous les acteurs est la même : la valorisation des tokens.
				</p>
				<div class="row gx-xl-10 gy-6">
					<div class="col-md-6">
						<div class="d-flex flex-row">

							<div>
								<h4 class="mb-1">0% de frais</h4>
								<p class="mb-0">Ni lors de l'achat de biens immobiliers, ni pour la gestion, la détention de tokens ou la revente de vos tokens.</p>
							</div>
						</div>
					</div>
					<!--/column -->
					<div class="col-md-6">
						<div class="d-flex flex-row">

							<div>
								<h4 class="mb-1">Valorisation</h4>
								<p class="mb-0">Chaque acteur gagne de l'argent grâce à la valorisation des tokens: exactement comme vous.</p>
							</div>
						</div>
					</div>
					<!--/column -->
				</div>
				<!--/.row -->
			</div>
			<!--/column -->
		</div>
		<!--/.row -->
	</div>
	<!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
	name: 'about1',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
