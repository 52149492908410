<template>
<div>

	<hero2 titre="Créez votre patrimoine immobilier 3.0"
				 text="Investissez en achetant des fractions de biens immobiliers et  réinvestissez les revenus pour vous bâtir un véritable patrimoine immobilier.
  <br/><br/>Rejoignez l'écosystème fraction.re et accumulez loyers, plus-value latente, intérêts composés et revenus financiers.  "
				 img="https://media.berlcoin.fr/ecosysteme.png"
				 link_label1="30 euros seulement pour commencer"
				 link_label2=""
				 link1="investir"
				 link2=""
				 bt1="rendement locatif"
				 bt2="plus-value latente"
				 bt3="intérêts composés"
				 bt4="revenus financiers "
				 bx1="Nous optimisons les rendements locatifs par le choix de l'emplacement central ou recherché des biens immobiliers, du type de bail et des coûts de gestion faibles."
				 bx2="La plus-value latente est le coeur de notre stratégie d'investissement: nous sélectionnons des adresses sur des secteurs géographiques à fort potentiel de croissance."
				 bx3="Nous réinvestissons l'ensemble des revenus locatifs dans l'immobilier via l'achat de tokens. Les intérêts composés rendent exponentielle la performance. "
				 bx4="Fraction.re est un écosytème qui bénéficie des avantages de la finance décentralisée pour générer des revenus additionnels avec les AMM et le staking."
				 bi1="https://media.berlcoin.fr/3.svg"
				 bi2="https://media.berlcoin.fr/2.png"
				 bi3="https://media.berlcoin.fr/6.svg"
				 bi4="https://media.berlcoin.fr/20945479.svg" />


	<feature1 accroche="devenez multiproprietaire facilement et sans contrainte"
						titre="Pour ceux qui veulent se constituer un patrimoine"
						text="L'écosystème immobilier fraction.re s'adresse à celles et ceux qui désirent se constituer un patrimoine immobilier diversifié
sans disposer d'un capital de départ important (30€ suffisent pour commencer)
et sans avoir les contraintes de recherche de biens, de gestion des locations et des déclarations administratives tout en bénéficiant des avantages des technologies 3.0 de la blockchain et d'une fiscalité plafonnée.
<br/><br/>La tokenisation permet d'investir sur une fraction d'un immeuble dont valeur de référence évolue quotidiennement
afin de refléter la valeur réélle de votre investissement en intégrant les loyers, les plus-values et les intérêts composés.
<br/><br/>Une fois votre compte ouvert dans la blockchain stellar, vous constituer un patrimoine immobilier devient très simple.
<br/><br/>"
						lead="<b>Un token représente une fraction de la propriété d'un bien immobilier </b>"
						img1="uil-building"
						img2="uil-chart-growth"
						img3="uil-layers-alt"
						img4="uil-wallet"
						color="soft-green"
						t1="Choix d'un bien et réception de tokens"
						t2="Valorisation quotidienne des tokens"
						t3="Réinvestissement automatique"
						t4="Vente sans frais sur la place de marché"
						l1=""
						l2=""
						l3=""
						l4=""
						p1="Choisissez un bien immobilier et recevez des tokens pour une fraction de la propriété de ce bien."
						p2="La valeur de référence des tokens évolue chaque jour en intégrant les revenus générés par ce bien. "
						p3="Les loyers perçus sont directement réinvestis dans des biens similaires. "
						p4="Revendez vos tokens presque sans frais et bénéficiez d'une fiscalité plafonnée." />

	<action1 titre="Investissez maintenant dans l'immobilier 3.0"
					 soustitre="Rejoignez l'écosystème Fraction.re"
					 link_label="Investir"
					 link="investir" />


	<feature2 v-if="false"
						accroche="L'immobilier 3.0"
						titre="L'investissement immobilier réinventé "
						lead="C'est à la base une idée simple : investir à plusieurs dans l'immobilier avec un smartphone et une carte bancaire."
						text="Pour mettre en pratique cette idée, nous avons recours au meilleur de la technologie actuelle : <b>la finance décentralisée</b>.
  <br/><br/>Cela permet de transformer un bien immobilier en actif financier, puis de créer des tokens garantie par cet actif qui sont échangeable sur une place de marché,
  via un smartphone.
  <br/><br/>C'est un processus technique et financier complexe pour que cela devienne très simple pour vous.
  <br/><br/>"
						t1="Immeuble"
						p1="Nous achetons un bien immobilier physique : un appartement, une maison, une place de parking..."
						k1=""
						l1=""
						img1="https://media.berlcoin.fr/f1.jpg"
						t2="Actif Financier"
						p2="Grâce à la finance décentralisée, nous transformons cet immeuble en actif financier."
						k2=""
						l2=""
						img2="https://media.berlcoin.fr/f2.jpg"
						t3="Tokenisation "
						p3="Nous émettons des tokens <b>Berlcoin</b> sur la blockchain dont la valeur est garantie par cet actif."
						k3=""
						l3=""
						img3="https://media.berlcoin.fr/f3.jpg"
						t4="Place de marché"
						p4="Vous achetez et vous vendez vos Berlcoins sur une place de marché avec votre smartphone."
						k4=""
						l4=""
						img4="https://media.berlcoin.fr/f4.jpg"
						color="soft-green" />




	<testimonial4 v-if="false"
								text="La tokenisation est une technologie révolutionnaire qui permet de fractionner un bien immobilier en utilisant la blockchain.
   <br/><br/>Un <b>NFT</b> représente l'intégralité des droits de propriété d'un bien immobilier.
   <br/><br/>Un <b>token</b> représente une fraction de la propriété de l'immobilier,  le rendant plus accessible aux petits investisseurs. "
								name=""
								subname=""
								star="false" />

	<process3 v-if="false"
						titre="L'immobilier fait sa révolution grâce à la finance décentralisée"
						soustitre="Comment cela fonctionne-t'il pour les investisseurs ?"
						soustitre2="Les avantages de la finance décentralisé"
						t1="achat de tokens"
						t2="valorisation"
						t3="revente"
						tx1="Achat de tokens sur la place de marché à cours défini ou à cours libre selon l'offre et la demande."
						tx2="La valeur des tokens augmentent en fonction des revenus et des plus values."
						tx3="Revente des tokens pour encaisser les bénéfices sans délai et en quelques clics."
						lead="Vous achetez et vendez des berlcoins dont la valeur est garantie par des biens immobiliers sans aucune contrainte."
						lead2='Nous transformons des actifs immobiliers en actifs financiers'
						it1="simplicité & garantie"
						it2="dynamisme & souplesse"
						it3="blockchain & sécurité"
						id1="Bénéficiez de la stabilité et de la garantie de l'immobilier pour réaliser des investissements aussi simplement qu’en utilisant un compte d’épargne."
						id2="Bénéficiez du dynamisme du marché immobilier pour réaliser des plus-values importantes sans les contraintes habituelles d'accès à ce marché."
						id3="Bénéficiez des technologies de la blockchain pour sécuriser et acheter ou revendre vos actifs sur n'importe quelle place de marché." />





	<process5 v-if="false"
						titre="Concrètement, qu'est ce que l'écosystème fraction ?"
						lead="L'écosystème fraction est un ensemble d'acteurs de l'immobilier qui tokenisent des biens, achetent, vendent et échangent des tokens puis en réinvestissent les revenus dans l'immobilier 3.0."
						it1="Création d'un NFT pour chaque bien immobilier."
						id1="Création un type particulier de token (un NFT) qui est la représentation numérique des droits de propriété spécifiques à chaque bien immobilier."
						it2="Création de tokens adossés à chaque NFT."
						id2="Le nombre de tokens créés dépend de la valeur du bien. Le portefeuille de garantie du token correspond à l’ensemble des biens convertis en NFT."
						it3="Réinvestissement des revenus dans l'immobilier."
						id3="Les revenus générés par les biens immobiliers sont réinvestis dans l'achat d'autres biens immobiliers qui s’ajoutent au portefeuille de garantie. "
						it4="Progression de la valeur des tokens en continue."
						id4="Les revenus des locations, l'effet de levier bancaire et la plus-value latente des biens augmentent la valeur des tokens." />

	<about1 v-if="false" />




	<testimonial2 v-if="false" />

	<hero2 v-if="false"
				 titre="Les piliers financiers de l'écosystème"
				 text="Si la blockchain est la structure technique, l'écosystème financiers des investissements reponsent sur 4 piliers :
             <li>plus value latente</li><li>rendement locatif</li> <li>levier bancaire</li>  <li>intérêts composés</li> "
				 img="https://media.berlcoin.fr/20.jpg"
				 link_label1=""
				 link_label2=""
				 link1=""
				 link2=""
				 bt2="rendement locatif"
				 bt1="plus value latente"
				 bt3="levier bancaire"
				 bt4="intérêts composés"
				 bx1="La plus-value latente est le coeur de la stratégie d'investissement: les plus values latente sont prise en compte chaque jour dans la valorisation des tokens."
				 bx2="Nous optimisons les rendements locatifs par le choix de l'emplacement central ou recherché des biens immobiliers, du type de bail et des coûts de gestion faibles."
				 bx3="Nous optimisons la rentabilité financière des acquisitions en utilisant un levier bancaire avec un prêt à taux préférentiel de 40% du coût total adossé à chaque bien acquis."
				 bx4="Nous réinvestissons l'ensemble des revenus locatifs dans l'immobilier. Les intérêts composés rendent exponentielle la performance globale de l'écosystème."
				 bi2="https://media.berlcoin.fr/3.png"
				 bi1="https://media.berlcoin.fr/2.png"
				 bi3="https://media.berlcoin.fr/1.png"
				 bi4="https://media.berlcoin.fr/4.png" />




	<blockimage bt1="Accessible à tous"
							bt2="Simple"
							bt3="Décentralisé"
							bt4="Valorisé chaque jour"
							bx1="Sans condition de revenu, sans apport, sans prêt bancaire et sans contrainte de gestion. "
							bx2="Une gestion intégrale, sans contrainte et sans soucis assurée par des professionnels. "
							bx3="Vous pouvez disposer des avantages de la DeFi pour générer des revenus additionnels.  "
							bx4="Chaque token a une valeur de référence indexée sur ses performances attendues.  "
							bi1="https://media.berlcoin.fr/8548.jpg"
							bi2="https://media.berlcoin.fr/simplecle.jpg"
							bi3="https://media.berlcoin.fr/20945463.jpg"
							bi4="https://media.berlcoin.fr/3711948.svg"
							link1="post/democatique"
							link2="post/simple"
							link3="post/decentralisation"
							link4="post/index" />

	<div class="container mb-12   ">
		<v-alert dense
						 type="success"
						 class="bg-green text-black">
			<a href="investir">
				<b class="mx-5 text-white"> Rejoignez les pionniers de l'investissement immobilier du futur </b>
			</a>
		</v-alert>
	</div>



	<blockimage bt1="Moderne"
							bt2="Sécurisé"
							bt3="Optimisé"
							bt4="Diversifié "
							bx1="Des apps modernes et conviviales pour rendre votre investissement simple à réaliser avec un smartphone. "
							bx2="La technologie de blockchain garantit la sécurité et la transparence de tous vos investissements."
							bx3="Les revenus générés par les biens immobiliers sont intégralement réinvestis dans l'achat d'autres biens. "
							bx4="Investir en France ou à l'international pour diversifier votre patrimoine et optimiser les rendements."
							bi1="https://media.berlcoin.fr/wallet.jpg"
							bi2="https://media.berlcoin.fr/20945597.jpg"
							bi3="https://media.berlcoin.fr/20943673.jpg"
							bi4="https://media.berlcoin.fr/10207.jpg"
							link1="post/app"
							link2="post/securite"
							link3="post/interet-composes"
							link4="post/diversification" />


	<div class="container mb-12   ">


		<v-alert dense
						 type="success"
						 class="bg-green ">
			<a href="investir">
				<b class="mx-5 text-white"> Rejoignez les pionniers de l'investissement immobilier du futur </b>
			</a>
		</v-alert>
	</div>


	<blockimage bt1="Contractuel... ou pas"
							bt2="Rentable"
							bt3="Fiscalement léger"
							bt4="Liquide "
							bx1="Signez un contrat de droit français pour investir ou achetez des tokens dans la blockchain sans persmission requise.  "
							bx2="L'immobilier comme source initiale de revenus, la finance décentralisée pour booster les performances."
							bx3="Vous bénéficiez de la fiscalité sur les bénéfices financiers et non sur l'immobilier détenu en direct."
							bx4="De la pierre solide pour les revenus, des tokens liquides vendables et transférables en quelques secondes."
							bi1="https://media.berlcoin.fr/sign.jpg"
							bi2="https://media.berlcoin.fr/rentable.jpg"
							bi3="https://media.berlcoin.fr/5.svg"
							bi4="https://media.berlcoin.fr/liquide.jpg"
							link1="post/contrat"
							link2="post/rentable"
							link3="post/taxation"
							link4="post/liquide" />


	<div class="container mb-12   ">
		<v-alert dense
						 type="success"
						 class="bg-green text-black">
			<a href="investir">
				<b class="mx-5 text-white"> Rejoignez les pionniers de l'investissement immobilier du futur </b>
			</a>
		</v-alert>
	</div>

	<process1 image="https://media.berlcoin.fr/device.png"
						accroche="L'application de l'immobilier 3.0"
						titre="Wallet & Marketplaces"
						lead="Nous mettons à votre disposition une app spécialisée pour vos investissements fractionnés"
						text="Cette application disponible via le site web ou sur votre smartphone vous permet  de réaliser et suivre facilement vos transactions:"
						:elements="el_process1"
						link1="investir"
						link_label1=""
						link_label2="" />


	<action1 titre="Investissez maintenant dans l'immobilier 3.0"
					 soustitre="Rejoignez l'écosystème Fraction.re"
					 link_label="Investir"
					 link="investir" />


	<feature3 />

	<testimonial1 accroche="Investir dans l'immobilier - Investir dans le futur"
								titre="Ce que les investisseurs pensent de  fraction.re "
								a1="Des rendements optimisés  et des garanties robustes"
								t1="J'ai investi dans l'écosystème fraction.re car il presente à mes yeux le meilleur ratio risque/rendement.
								En immobilier il est très difficile au niveau individuel d'obtenir de tels rendements en prenant si peu de risque et sans capital important."
								nom1="Paul Lemoire"
								profession1="Analyste financier"
								a2="Simplicité, délégation et plus-value"
								t2="J'ai investi dans des immeubles de rapport, cela me prend beaucoup de temps et la rentabilité n'est pas toujours là.
                Avec l'écosystème fraction.re, j'ai trouvé la solution idéale :
								j'investis, je n'ai rien à faire et je prends mes plus-values latentes quand je le veux."
								nom2="Jeanne Blacat"
								profession2="Agent immobilier"
								a3="Un risque mutualisé, des smart contrats rassurants"
								t3="En achetant à plusieurs de nombreux biens, on mutualise les risques. Avec les smarts contrats et la blockchain : pas de peur, d’incertitude ou de doute, je suis certain que les dispositions contractuelles seront exécutées."
								nom3="Elise Deloime"
								profession3="Informaticienne"
								avatar1="https://media.berlcoin.fr/avatars/2004.jpg"
								avatar2="https://media.berlcoin.fr/avatars/2031.jpg"
								avatar3="https://media.berlcoin.fr/avatars/2138.jpg" />



	<testimonial3 />


	<action1 titre="Investissez maintenant dans l'immobilier 3.0"
					 soustitre="Rejoignez l'écosystème Fraction.re"
					 link_label="Investir"
					 link="investir" />


</div>
</template>

<script>

import hero2 from "@/components/hero2.vue";
import feature1 from "@/components/feature1.vue";
import feature2 from "@/components/feature2.vue";
import feature6 from "@/components/feature6.vue";
import testimonial4 from "@/components/testimonial4.vue";
import testimonial1 from "@/components/testimonial1.vue";
import process5 from "@/components/process5.vue";
import testimonial2 from "@/components/testimonial2.vue";
import organigram1 from "@/components/organigram-1.vue";
import feature7 from "@/components/feature7.vue";
import portfolio1 from "@/components/portfolio-1.vue";
import process1 from "@/components/process1.vue";
import process3 from "@/components/process3.vue";
import price2 from "@/components/price2.vue";
import about1 from "@/components/about1.vue";
import action1 from "@/components/action1.vue";
import about3 from "@/components/about3.vue";
import blockimage from "@/components/blocks_image.vue";
import price1 from "@/components/price1.vue";
import feature3 from "@/components/feature3.vue";
import testimonial3 from "@/components/testimonial3.vue";

export default {
  name: 'Home',
  components: {
    feature1,testimonial1 ,price1 ,feature3 ,testimonial3 ,
    feature2,
    feature6,
    feature7,
    hero2,
    testimonial4,
    testimonial2,
    process1,
about1,
action1,
    process5,
    organigram1,
    portfolio1,
    process3,
    price2,
    about3,
    blockimage
  },
  props: {},
  data: () => ({


 exemples: [{

        image:"https://media.berlcoin.fr/22.svg",
   title:"",
   text: "",
   link: "",
   category:"Résidentiel locatif"
 },

 {
  image:"https://media.berlcoin.fr/3347.svg",
  title:"",
  text: "",
  link: "",
   category:"Déficit foncier"
 },
 {
  image:"https://media.berlcoin.fr/32321.svg",
  title:"",
  text: "",
  link: "",
   category:"Location en Meublé"
 },


 {
  image:"https://media.berlcoin.fr/5765699.svg",
  title:"",
  text: "",
  link: "",
   category:"Parking, box et garage"
 },
 {
    image:"https://media.berlcoin.fr/20943821.svg",
    title:"",
    text: "",
    link: "",
   category:"Viager"
 },


 {
  image:"https://media.berlcoin.fr/24.svg",
  title:"",
  text: "",
  link: "",
   category:"Terrain, forêt, vignobles"
 },


  {
    image:"https://media.berlcoin.fr/19198806.svg",

    title:"",
    text: "",
    link: "",
    category:"Location saisonnière"
  },
  {
    image:"https://media.berlcoin.fr/21.svg",
    title:"",
    text: "",
    link: "",
 category:"Immeuble complet"
      },
      {
    image:"https://media.berlcoin.fr/1035.svg",
    title:"",
    text: "",
    link: "",
     category:"Bureaux et murs d’entreprise"
   },
   {
      image:"https://media.berlcoin.fr/20945159.svg",
      title:"",
      text: "",
      link: "",
  category:"Achat-revente "
       },
       {
      image:"https://media.berlcoin.fr/1763.svg",
      title:"",
      text: "",
      link: "",
      category:"Colocation"
    },
    {
      image:"https://media.berlcoin.fr/949.svg",
      title:"",
      text: "",
      link: "",
   category:"International"
 },
      ]
,
    elements: [{
      image:"https://media.berlcoin.fr/settings_1.svg",
      title:"Configuration et utilisation faciles",
      text: "Pas besoin de connaissances complexes ou de personnel informatique supplémentaire.      Un gestionnaire dédié peut facilement exécuter des opérations sur le dashboard.",
      link: "",
    }, {
      image:"https://media.berlcoin.fr/icon-stand-alone.svg",
      title:"Intégrer à votre entreprise",
      text: " Le tableau de bord personnalisé se déploie dans le cadre de votre site Web d'entreprise et devient une passerelle pour vos actionnaires ou investisseurs.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/icon-dashboard.svg",
      title:"1 tableau de bord, 2 interfaces",
      text: "Le dashboard est optimisé pour deux environnements uniques. L'un est pour la gestion d'entreprise, un autre est pour les investisseurs et les actionnaires.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/icon-legal.svg",
      title:"Aucune complexité juridique",
      text: "Pas besoin d'autorisations des régulateurs pour gérer votre propre registre sur la blockchain si la gestion est assurée par les investisseurs dans le cadre d’une mise en commun de moyens.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/gdpr_1.svg",
      title:"Les données sont à vous",
      text: "fraction ne partage pas vos investisseurs avec d'autres et fournit une infrastructure prête à l'emploi pour stocker les données de vos investisseurs. Conforme au RGPD.",
      link: "",
    },{
      image:"https://media.berlcoin.fr/icon-security.svg",
      title:"La sécurité est la priorité",
      text: "Outre l'utilisation d'un registre immuable sur la blockchain, nous utilisons la meilleure pile technologique pour nous assurer que vos données sont sûres et sécurisées.",
      link: "",
    }, ],

el_process1: [
    {text:"Achat & vente"},
        {text:"Transfert"},
  {text:"Souscription de contrats à terme (staking)"},
  {text:"Accès direct aux places de marché"},
  {text:"Consultation de l'historique des transactions"},
    {text:"Votation"},
        {text:"Dépôt et retrait des Liquidity Pools"},
],

offres: [
    {
      prix : "190",
      titre:"Mono project",
      puces:[
        { icon:"check",couleur: "green",  text:"Frais d'installation : 2500 €" },
        { icon:"check",couleur: "green",    text:"1 project " },
        { icon:"check",couleur: "green",    text:"Interface de gestion" },
        { icon:"check",couleur: "green",    text:"Wallet" },
        { icon:"check",couleur: "green",    text:"Place de marché" },
        { icon:"check",couleur: "green",    text:"Votation" },
        { icon:"times",couleur: "red",    text:"Portefeuille de NFT" },
        { icon:"times",couleur: "red",    text:"Convertibilité ETH" },
        { icon:"times",couleur: "red",    text:"Intégration écosystème fraction.re" },
      ],
      liens:"",
      label:""
    },
    {
      prix : "490",
      titre:"Multi projects",
      puces:[
        { icon:"check",couleur: "green",  text:"Frais d'installation : 5000 €" },
        { icon:"check",couleur: "green",    text:"Multi-projects " },
        { icon:"check",couleur: "green",    text:"Interface de gestion" },
        { icon:"check",couleur: "green",    text:"Wallet" },
        { icon:"check",couleur: "green",    text:"Place de marché" },
        { icon:"check",couleur: "green",    text:"Votation" },
        { icon:"check",couleur: "green",    text:"Portefeuille de NFT" },
        { icon:"check",couleur: "green",    text:"Convertibilité ETH" },
        { icon:"check",couleur: "green",    text:"Intégration écosystème fraction.re: 6.5%" },
      ]
      ,
      liens:"",
      label:""
    },
  ]

  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {
		// j'investi, je n'ai r
		// j'investis, je n'ai r

	}
  }
  </script>

  <style scoped>
  </style>
