import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n' // npm install vue-i18n@8

import CKEditor from '@ckeditor/ckeditor5-vue2';
import vuetify from './plugins/vuetify'
import VueMatomo from 'vue-matomo';

Vue.use( CKEditor );
Vue.use(VueI18n); // Utilisation de VueI18n dans Vue





// Création d'une instance i18n exportée pour être utilisée partout dans Vue
function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: loadLocaleMessages()
})



Vue.config.productionTip = false

Vue.use(VueMatomo, {
  host: "https://webinbox.info/",
  siteId: 2,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});


Vue.mixin({
  methods: {

    monetary_aspect(temp)
      {
        let retour =
         parseInt(Math.round(parseFloat(temp) * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        + '.<small>'
        + Math.round(parseFloat(temp) * 100).toString().slice(-2)
        + '</small>';
if(retour=="0.<small>0</small>")  retour="0.<small>00</small>"


        return retour ;
      },
    saveFile: function(publickey) {
      let arr = ({
        key: publickey
      });
      const data = JSON.stringify(arr)
      window.localStorage.setItem('berlcoin', data);
    },

    getKey: function() {
      let txtls = window.localStorage.getItem('berlcoin');
      if (txtls != null) {
        let data = JSON.parse(txtls);
        return data.key;
      } else return false;
    },

    check_condition: function(temp, condition) {
      let show = true;
      if (condition.substring(0, 1) == "=")
        if (temp == condition.substring(1, 99)) show = false;
      if (condition.substring(0, 1) == ">")
        if (temp > parseInt(condition.substring(1, 99))) show = false;
      if (condition.substring(0, 1) == "<")
        if (temp < parseInt(condition.substring(1, 99))) show = false;
      if (condition.substring(0, 2) == ">=")
        if (temp >= parseInt(condition.substring(2, 99))) show = false;
      if (condition.substring(0, 2) == "<=")
        if (temp <= parseInt(condition.substring(2, 99))) show = false;
      if (condition.substring(0, 2) == "!=")
        if (temp != condition.substring(2, 99)) show = false;
      return show;
    },

    avatar_photo: function(id) {
      return this.$store.state.https + "api/avatar.php?id=" + id;
    },
    extraction: function(text, start, end = ";") {

      let return_value = "";

      let temp = text.split("\n").join("");
      temp = temp.replace(/\n|\r|(\n\r)/g, ' ');
      temp = temp.replace("\n", "").split(end);

      temp.forEach((item) => {

        if (item.trim().substr(0, start.length) == start) return_value = item.replace(start, "")
      })

      return return_value.trim();
    },

    // ------------------------------------------------------------------------------------------------------ PRESS ONLY NUMBER
    onlyNumber: function onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    // ------------------------------------------------------------------------------------------------------ OPTIONS
    options_tab: function(options) {
      let opt = [];
      if (options != "") {
        let temp = options.split(";");
        temp.forEach((item) => {
          let temp2 = item.split(":");
          if (temp2[0] != "") opt[temp2[0].trim()] = temp2[1].trim();
        })
        return opt;
      } else return [];
    },

    // ------------------------------------------------------------------------------------------------------ COOKIES SET
    setCookie: function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + ";SameSite=Lax";
    },
    // ------------------------------------------------------------------------------------------------------ COOKIE GET
    getCookie: function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
    },
    // ------------------------------------------------------------------------------------------------------ utilisé par autodisplay v 1

    consolepost: function(api, data) {
      let log = "";
      let logget = "";
      for (var pair of data.entries()) {
        log += "$_POST['" + pair[0] + "']= '" + pair[1] + "' ;\n";
        logget += "&" + pair[0] + "=" + pair[1];
      }
      if (this.debug) {
        console.log("APPEL API  : " + api.value + " par " + this.composant_name);
        console.log(log + "\n" + "?" + logget + "\n");
      }
    },
    // ------------------------------------------------------------------------------------------------------ CONSOLE

    console: function(source, data) {
      if (this.debug) {
        console.log(source + ":" + data + " [" + typeof(data) + "]");
        if (typeof(data) == 'object')
          console.log(data);
      }
    },


  },

})





new Vue({
  router,
  store,
  vuetify,
    i18n,
  render: h => h(App)
}).$mount('#app')
