<template>
<section class="wrapper bg-light">
  <div class="container py-3 py-md-4 mb-16 pb-md-4">
    <div class="row">
      <div class="col-xl-12">
        <div class="card image-wrapper bg-full bg-image  ">
          <div class="card-body bg-green gradient-6 p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
            <h5 class=" text-white">{{titre}}</h5>
            <h5 class=" text-white">{{soustitre}}</h5>
            <a :href="link" class="btn btn-white   mb-0 text-nowrap">{{link_label}}</a>
          </div>
          <!--/.card-body -->
        </div>
        <!--/.card -->
      </div>
      <!-- /column -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->
</template>


<script>
export default {
  name: 'action1',
  components: {},
  props: {
    titre: String,
    soustitre: String,
    link:String,
    link_label:String
  },
  data: () => ({


  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
