<template>
<nav class="navbar navbar-expand-lg center-nav navbar-dark navbar-bg-dark">
	<div class="container flex-lg-row flex-nowrap align-items-center">
		<div class="navbar-brand w-100">
			<a :href="link"
				 class="h1 text-white">
				<b v-html="title"></b>
			</a>

		</div>


		<div class="navbar-collapse offcanvas-nav  ma-0  pa-0">

			<ul class="navbar-nav  ma-0  pa-0">
				<li v-for="(item, index) in pages"
						:key="'dm'+index"
						class="nav-link ">
					<a class=" "
						 :href="'/'+item.link">{{item.page}}</a>
				</li>

			</ul>
			<!-- /.navbar-nav -->
		</div>


		<div class="navbar-other w-100 d-flex ms-auto">
			<ul class="navbar-nav flex-row align-items-center ms-auto "
					data-sm-skip="true">
				<li class="nav-item dropdown language-select text-uppercase d-lg-none">
					<a class="nav-link dropdown-item  "
						 href="#"
						 role="button"
						 data-bs-toggle="dropdown"
						 aria-haspopup="true"
						 aria-expanded="false"><i class="uil uil-align-justify"></i></a>
					<ul class="dropdown-menu">
						<li v-for="(item, index) in pages"
								:key="'dm'+index"
								class="nav-item">
							<a class="dropdown-item"
								 :href="'/'+item.link">{{item.page}}</a>
						</li>

					</ul>
				</li>

				<li class="nav-item  ">
					<a href="/Investir"
						 class="btn btn-sm btn-success  ">Investir</a>
				</li>

			</ul>
		</div>
		<!-- /.navbar-collapse -->


	</div>
</nav>
</template>


<script>
export default {
	name: 'nav1',
	components: {},
	props: {
		title: String,
		title2: String,
		link: {
			type: String,
			default: "/"
		},
		pages: [],
		langs: [],

	},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>
