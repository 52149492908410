import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tarifs',
    name: 'Tarifs',
    component: () => import('../views/Tarifs.vue')
  },
  {
    path: '/cgu',
    name: 'cgu',
    component: () => import('../views/cgu.vue')
  },
  {
    path: '/property',
    name: 'property',
    component: () => import('../views/property.vue')
  },
  {
    path: '/sci',
    name: 'sci',
    component: () => import('../views/sci.vue')
  },
  {
    path: '/investisseur',
    name: 'investisseur',
    component: () => import('../views/investisseur.vue')
  },
  {
    path: '/questions',
    name: 'questions',
    component: () => import('../views/faq.vue')
  },
  {
    path: '/realproperty',
    name: 'realproperty',
    props: {
      page: "realproperty",
      sidebar: true
    },
    component: () => import('../views/post.vue')
  },
  {
    path: '/taxation',
    name: 'taxation',
    props: {
      page: "taxation",
      sidebar: true
    },
    component: () => import('../views/post.vue')
  },
  {
    path: '/decentralization',
    name: 'decentralization',
    props: {
      page: "decentralization",
      sidebar: true
    },
    component: () => import('../views/post.vue')
  },
  {
    path: '/post/:id',
    name: 'post',
    props: {
      sidebar: true
    },
    component: () => import('../views/post.vue')
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: () => import('../views/edit.vue')
  },
  {
    path: '/creation_compte',
    name: 'creation_compte',
    component: () => import('../views/creation_compte.vue')
  },
  {
    path: '/investir',
    name: 'investir',
    component: () => import('../views/property.vue')
  },
  {
    path: '/token/:id',
    name: 'token',
    component: () => import('../views/property.vue')
  },
  {
    path: '/souscrire/:id',
    name: 'souscrire',
    component: () => import('../views/souscrire.vue')
  },
  {
    path: '/tdc',
    name: 'tiers_de_confiance',
    props: {
      page: "tiers_de_confiance",
      sidebar: true
    },
    component: () => import('../views/smart/tiersdeconfiance.vue')
  },
  {
    path: '/staking',
    name: 'staking',
    props: {
      page: "staking",
      sidebar: true
    },
    component: () => import('../views/smart/staking.vue')
  }, {
    path: '/ordreachat',
    name: 'ordreachat',
    props: {
      page: "ordreachat",
      sidebar: true
    },
    component: () => import('../views/smart/ordreachat.vue')
  },
  {
    path: '/ammachat',
    name: 'ammachat',
    props: {
      page: "ammachat",
      sidebar: true
    },
    component: () => import('../views/smart/ammachat.vue')
  },
  {
    path: '/deleteasset',
    name: 'deleteasset',
    props: {
      page: "deleteasset",
      sidebar: true
    },
    component: () => import('../views/smart/deleteasset.vue')
  },
  {
    path: '/sellxlm',
    name: 'sellxlm',
    props: {
      page: "sellxlm",
      sidebar: true
    },
    component: () => import('../views/smart/sellxlm.vue')
  },
  {
    path: '/sellasset',
    name: 'sellasset',
    props: {
      page: "sellasset",
      sidebar: true
    },
    component: () => import('../views/smart/sellasset.vue')
  },
  {
    path: '/sellamm',
    name: 'sellamm',
    props: {
      page: "sellamm",
      sidebar: true
    },
    component: () => import('../views/smart/sellamm.vue')
  },
  {
    path: '/transfert',
    name: 'transfert',
    props: {
      page: "transfert",
      sidebar: true
    },
    component: () => import('../views/smart/transfert.vue')
  },
  {
    path: '/transfertterm',
    name: 'transfertterm',
    props: {
      page: "transfertterm",
      sidebar: true
    },
    component: () => import('../views/smart/transfertterm.vue')
  },
  {
    path: '/pool',
    name: 'pool',
    props: {
      page: "pool",
      sidebar: true
    },
    component: () => import('../views/smart/pool.vue')
  },
  {
    path: '/smart',
    name: 'smart',
    props: {
      page: "smart",
      sidebar: true
    },
    component: () => import('../views/smart.vue')
  },
  {
    path: '/exit',
    name: 'exit',
    props: {
      page: "exit",
      sidebar: true
    },
    component: () => import('../views/smart/exit.vue')
  },

  {
    path: '/envoi',
    name: 'envoi',
    props: {
      page: "envoi",
      sidebar: true
    },
    component: () => import('../views/smart/transfert_envoi.vue')
  },
  {
    path: '/reception',
    name: 'reception',
    props: {
      page: "reception",
      sidebar: true
    },
    component: () => import('../views/smart/transfert_reception.vue')
  },
  {
    path: '/trustline',
    name: 'trustline',
    props: {
      page: "trustline",
      sidebar: true
    },
    component: () => import('../views/smart/trustline.vue')
  },
  {
    path: '/open',
    name: 'open',
    props: {
      page: "open",
      sidebar: true
    },
    component: () => import('../views/smart/smart_open.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    props: {
      page: "wallet",
      sidebar: true
    },
    component: () => import('../views/smart/wallet.vue')
  },
  {
    path: '/francais',
    name: 'francais',
    props: {
      page: "francais",
      sidebar: true
    },
    component: () => import('../views/smart/francais.vue')
  },
  {
    path: '/kyc',
    name: 'kyc',
    props: {
      page: "kyc",
      sidebar: true
    },
    component: () => import('../views/smart/kyc.vue')
  },
  {
    path: '/sign',
    name: 'sign',
    props: {
      page: "sign",
      sidebar: true
    },
    component: () => import('../views/smart/sign.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    props: {
      page: "shop",
      sidebar: true
    },
    component: () => import('../components/shop1.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
